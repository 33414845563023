import axios from "axios";

export const api = axios.create({
  baseURL: "https://seiko-global.com/api/v1/",
});

export const get = {
  getBanner: () => {
    return api
      .get(`1banner/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getAbout: () => {
    return api
      .get(`2about_us/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getMission: () => {
    return api
      .get(`3our_mission/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getProduct: () => {
    return api
      .get(`5about_product/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getHealth: () => {
    return api
      .get(`6about_health/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getCapabilities: () => {
    return api
      .get(`7your_capabilities/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getAction: () => {
    return api
      .get(`8action/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getCooperation: () => {
    return api
      .get(`9about_cooperation/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getNews: () => {
    return api
      .get(`news/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getProducts: () => {
    return api
      .get(`products/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getProductDetail: (id) => {
    return api
      .get(`products/${id}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getContacts: () => {
    return api
      .get(`contacts/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getNewsPage: () => {
    return api
      .get(`news_page/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getLogo: () => {
    return api
      .get(`logos/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getAboutCompany: () => {
    return api
      .get(`4about_company/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getSocial: () => {
    return api
      .get(`social_media/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getLoginLink: () => {
    return api
      .get(`login_link/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
  getNewsDetail: (id) => {
    return api
      .get(`news/${id}/`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error("Error fetching slider items:", error);
        throw error;
      });
  },
};
