import "./App.css";
import "./../src/assets/styles/fonts.css";
import { Route, Routes } from "react-router-dom";
import Main from "./components/Main";
import AboutUs from "./Pages/AboutUs";
import Header from "./components/Header";
import Footer from "./components/Footer";
import News from "./Pages/News";
import AllProduct from "./Pages/AllProduct";
import Detail from "./Pages/Detail";
import Busket from "./Pages/Busket";
import "./assets/styles/media.css";
import ScrollToTop from "./ScrollToTop";
import { useEffect } from "react";
import { get } from "./Api/Api";
import DetailNews from "./Pages/DetailNews";
function App() {
  useEffect(() => {
    const fetchLogoData = async () => {
      try {
        const response = await get.getLogo();
        const { favicon } = response[0];

        const linkElement = document.querySelector("link[rel='icon']");
        if (linkElement) {
          linkElement.href = favicon;
        }
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchLogoData();
  }, []);
  return (
    <div className="App">
      <ScrollToTop/>
      <Header />
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="about" element={<AboutUs />} />
        <Route path="news" element={<News />} />
        <Route path="all-product" element={<AllProduct />} />
        <Route path="detail/:id" element={<Detail />} />
        <Route path="busket" element={<Busket />} />
        <Route path="detail-news/:id" element={<DetailNews />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
