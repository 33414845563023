import React, { useEffect, useState } from "react";
import news from "./../assets/Image/guys.png";
import { get } from "../Api/Api";
import { useNavigate, useParams } from "react-router-dom";
export default function DetailNews() {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const {id} = useParams()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getNewsDetail(id);
        setProducts(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, [id]);
  const [aboutDetail, setAboutDetailt] = useState({
    first: [],
    second: [],
    video: [],
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getNewsPage();
        setAboutDetailt(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, [id]);
  return (
    <div className="container">
      {products.map((el) => (
        <div>
          <div className="news-det">
            <img className="news-detail" src={el.image} alt="" />
            <div className="komflex">
              <h1>{el.title}</h1>
              <p>{el.created}</p>
            </div>
          </div>
          <div className="pred">
          <p dangerouslySetInnerHTML={{__html:el.description}}></p>
          </div>
        </div>
      ))}
      <div className="littsg">
        <div className="list_item">
          {aboutDetail.second.length > 0 && (
            <div className="list">
              <img className="last" src={aboutDetail.second[0].image} alt="" />
              <div className="nevember">
                <p>{aboutDetail.second[0].created} </p>
                <h1>{aboutDetail.second[0].title}</h1>
                <h3
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/detail-news/${aboutDetail.second[0].id}`)}
                >
                  читать больше
                </h3>
              </div>
            </div>
          )}
        </div>

        <div className="list_item">
          <div className="lasy_items">
            {aboutDetail.second.slice(0, 2).map((item) => (
              <div key={item.id} className="lasy_item">
                <img className="last_img topxsds" src={item.image} alt="" />
                <p>{new Date(item.created).toLocaleDateString("ru-RU")}</p>
                <h2>{item.title}</h2>
                <h3
                  style={{ cursor: "pointer" }}
                  onClick={() => navigate(`/detail-news/${item.id}`)}
                >
                  читать больше
                </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
