import React, { useEffect, useState } from "react";
import logo from "./../assets/Image/logo.png";
import basket from "./../assets/Image/Basket.png";
import arrow from "./../assets/Image/arrow.png";
import "../Styles/header.css";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { get } from "../Api/Api";

export default function Header() {
  const location = useLocation();
  const isDetailPage = location.pathname.startsWith("/detail/") || location.pathname.startsWith("/detail-news");
  const navigate = useNavigate();
  const [cartCount, setCartCount] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const updateCartCount = () => {
    const cartItems = JSON.parse(localStorage.getItem("cart")) || [];
    const totalItems = cartItems.reduce((sum, item) => sum + item.quantity, 0);
    setCartCount(totalItems);
  };

  useEffect(() => {
    updateCartCount();
    window.addEventListener("cartUpdated", updateCartCount);
    return () => {
      window.removeEventListener("cartUpdated", updateCartCount);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = !isMenuOpen ? "hidden" : "auto";
  };

  const handleLinkClick = (e) => {
    e.stopPropagation();
    setIsMenuOpen(false);
    document.body.style.overflow = "auto";
  };
  const [about, setQAbout] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getLoginLink();

        setQAbout(response);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <div
      id="header"
      style={{
        backgroundColor: isDetailPage ? "#000080" : "transparent",
        transition: "background-color 0.3s",
        paddingTop: "20px",
        paddingBottom: "20px",
        marginTop: "-20px",
      }}
    >
      <div className="container">
        <div className="header">
          <img className="logo" onClick={()=>navigate("/")} src={logo} alt="" />

          <div className={`pages ${isMenuOpen ? "menu-open" : ""}`}>
            <NavLink className="page" to="/" onClick={handleLinkClick}>
              Главная
            </NavLink>
            <NavLink className="page" to="/about" onClick={handleLinkClick}>
              О нас
            </NavLink>
            <NavLink
              className="page"
              to="/all-product"
              onClick={handleLinkClick}
            >
              Продукция
            </NavLink>
            <NavLink className="page" to="/news" onClick={handleLinkClick}>
              Новости
            </NavLink>
          </div>

          <div className="basket_item">
            <div
              className="basket-container"
              onClick={() => navigate("/busket")}
            >
              <img className="basket" src={basket} alt="" />
              {cartCount > 0 && <span className="cart-count">{cartCount}</span>}
            </div>

            {about.map((el) => (
              <a href={el.login_link} target="_blank" style={{textDecoration:"none"}}>
                <button className="btns">
                  Войти <img className="aroow" src={arrow} alt="" />
                </button>
              </a>
            ))}
            <div
              className={`burger-menu ${isMenuOpen ? "open" : ""}`}
              onClick={toggleMenu}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
