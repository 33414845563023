import React, { useEffect, useState } from "react";
import but from "./../assets/Image/butilka.png";
import wellness from "./../assets/Image/wellness.png";
import protein from "./../assets/Image/protein.png";
import sal from "./../assets/Image/sal.png";
import { get } from "../Api/Api";
import { useNavigate } from "react-router-dom";

export default function Catalog() {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await get.getProducts();
        setProducts(response.results);
      } catch (error) {
        console.error("Ошибка при получении данных:", error);
      }
    };

    fetchData();
  }, []);

  const renderProduct = (product, index) => {
    if (index % 2 === 0) {
      return (
        <div key={product.id} className="topx sticy">
          <img className="welln" src={wellness} alt="" />
          <div className="card-items">
            <div className="buts">
              <img src={product.image || but} alt={product.title} />
            </div>
            <div className="Glucosamine">
              <div className="colllegan">
                <h1>{product.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: product.ingredients }} />
              </div>
              <div className="hair">
                {product.advantages && product.advantages.map((advantage, index) => (
                  <p key={index}>+ {advantage.title}</p>
                ))}
                {(!product.advantages || product.advantages.length === 0) && (
                  <>
                    <p>+ Очищает от вредных токсинов</p>
                    <p>+ Поддерживает здоровый уровень </p>
                    <p>+ Снижает риск хронических заболеваний</p>
                  </>
                )}
                <button className="btn" onClick={() => navigate(`/detail/${product.id}`)}>Подробнее</button>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div key={product.id} className="topx sticy">
          <img className="welln" src={wellness} alt="" />
          <div className="card-items">
            <div className="Glucosamine">
              <div className="colllegan">
                <h1>{product.title}</h1>
                <div dangerouslySetInnerHTML={{ __html: product.ingredients }} />
              </div>
              <div className="hair">
                {product.advantages && product.advantages.map((advantage, index) => (
                  <p key={index}>+ {advantage.title}</p>
                ))}
                {(!product.advantages || product.advantages.length === 0) && (
                  <>
                    <p>+ Очищает от вредных токсинов</p>
                    <p>+ Поддерживает здоровый уровень </p>
                    <p>+ Снижает риск хронических заболеваний</p>
                  </>
                )}
                <button className="btn" onClick={() => navigate(`/detail/${product.id}`)}>Подробнее</button>
              </div>
            </div>
            <div className="buts">
              <img src={product.image || but} alt={product.title} />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="container">
      <div className="text_title flex">
        <p>Каталог </p>
        <a onClick={() => navigate(`/all-product`)}  className="product">
          Все продукты
        </a>
      </div>

      {products.map((product, index) => renderProduct(product, index))}
    </div>
  );
}
